import * as amplitude from "@amplitude/analytics-browser";
import { Alert, Spin } from "antd";
import axios from "axios";
import qs from "query-string";
import React from "react";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import { captureException } from "../sentry";
import Store from "../stateStore";
import { getBackendUrl } from "../utils";

function ConnectionContainer() {
  const history = useHistory();
  const { search } = history.location;
  const parsedSearch = qs.parse(search);
  const store = Store.useStore();
  const [loading, setLoading] = React.useState(false);
  const [orgId, _] = React.useState("");
  const [error, setError] = React.useState("");
  const params = useParams();
  const { id } = params as any;
  const { platform, shopifystore, redirect_url, return_url } = parsedSearch; // Case matters https://docs.rutter.com/connections/connection-url

  React.useEffect(() => {
    // effect run once
    setLoading(true);
    amplitude.track("Instant Link Initiated");
    axios
      .get(`${getBackendUrl()}/link/initupdate`, {
        params: {
          itemId: id,
        },
      })
      .then((response) => {
        const { data } = response;
        const { token, needsReauth, authenticationConfigs, lastPlatform } =
          data;
        const query = qs.stringify({
          ...parsedSearch,
          token,
          itemid: id,
        });
        amplitude.setGroup("orgId", orgId);
        amplitude.setGroup("orgPublicToken", token as string);
        if (needsReauth) {
          amplitude.track("Reauth Link");
        }
        store.set("isUpdate")(true);
        store.set("itemId")(id);
        if (lastPlatform) {
          store.set("autoPlatform")(lastPlatform as any);
        } else if (platform) {
          amplitude.track("Platform Specific Instant Link");
          store.set("autoPlatform")(platform as any);
        }
        if (shopifystore) {
          store.set("autoShopifyStore")(shopifystore as string);
        }
        if (redirect_url) {
          store.set("customRedirectUrl")(redirect_url as string);
        }
        if (return_url) {
          store.set("customReturnUrl")(return_url as string);
        }

        if (authenticationConfigs) {
          store.set("authenticationConfigs")(authenticationConfigs);
        }
        history.push(`/?${query}`);
        try {
          localStorage.setItem("rutterOrgId", orgId);
        } catch (e) {
          captureException(e);
        }
      })
      .catch((e) => {
        captureException(e, { itemid: id });
        if (e.message === "Network Error") {
          // user/dev
          setError("Error connecting to Rutter. Please try again later.");
        } else if (e?.response?.data?.error === "INVALID_PUBLIC_KEY") {
          // dev
          setError(
            "Invalid Rutter public key. To find your public key, visit https://dashboard.rutterapi.com",
          );
        } else if (
          e?.response?.data?.error_message === "Invalid connection url"
        ) {
          setError("This connection does not exist.");
        } else {
          setError(
            "An unexpected error occurred, and we're looking into it. Please try again later.",
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 99,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Spin></Spin>
      </div>
    );
  }

  if (error) {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 99,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Alert
          type="error"
          message={"Error"}
          description={error}
          showIcon></Alert>
      </div>
    );
  }

  return (
    <>
      <Switch>
        <Route path="/">
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 99,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}>
            {error}
          </div>
        </Route>
      </Switch>
    </>
  );
}

export default ConnectionContainer;
