import * as amplitude from "@amplitude/analytics-browser";
import { Alert } from "antd";
import axios from "axios";
import qs from "query-string";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { UNKNOWN_ERROR_MESSAGE } from "../constants";
import { persistLinkState } from "../hooks/useLinkTracker";
import { captureException } from "../sentry";
import Store from "../stateStore";
import { extractLinkParamsFromState, getBackendUrl, STORES } from "../utils";

type Props = {
  clientSecret: string;
  onBack: () => void;
  onCancel: () => void;
  onComplete: (publicToken: string) => void;
};

function QuickBooksHRIS({ clientSecret, onComplete }: Props) {
  const store = Store.useStore();
  const history = useHistory();

  const [error, setError] = useState<string>("");

  useEffect(() => {
    if (store.get("isSandbox")) {
      store.set("handleSandboxComplete")(handleFakeComplete);
      history.push("/sandboxauth");
      return;
    }
    amplitude.setGroup("platform", STORES.QUICKBOOKS_HRIS);
    handleComplete();
  }, []);

  const handleAuthError = (error: unknown) => {
    captureException(error);
    if (axios.isAxiosError(error) && error.response?.data.error_message) {
      setError(error.response.data.error_message);
    } else {
      setError(UNKNOWN_ERROR_MESSAGE);
    }
  };

  const handleComplete = async () => {
    try {
      persistLinkState(STORES.QUICKBOOKS_HRIS, store.getState());
      const query = qs.stringify({
        ...extractLinkParamsFromState(store),
        platform: STORES.QUICKBOOKS_HRIS,
      });
      const url = `${getBackendUrl()}/authentication/proxy/app-url?${query}`;
      window.location.replace(url);
    } catch (error: unknown) {
      handleAuthError(error);
    }
  };

  const handleFakeComplete = async () => {
    try {
      const response = await axios.post(
        `${getBackendUrl()}/link/token/fulfill`,
        {
          type: STORES.QUICKBOOKS_HRIS,
          clientId: clientSecret,
          username: "user_good",
          password: "pass_good",
        },
      );
      const { data } = response;
      const { public_token } = data;
      onComplete(public_token);
    } catch (error: unknown) {
      handleAuthError(error);
    }
  };

  if (error) {
    return (
      <Alert
        className="mt-4"
        type="error"
        message={"Error"}
        description={error}
        showIcon
      ></Alert>
    );
  }

  return null;
}

export default QuickBooksHRIS;
