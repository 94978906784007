import * as amplitude from "@amplitude/analytics-browser";
import styled from "@emotion/styled";
import { Spin } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { captureException } from "../sentry";
import { getBackendUrl } from "../utils";

interface Props {
  setBankId: React.Dispatch<React.SetStateAction<string>>;
  setBankRoutingNumber: React.Dispatch<React.SetStateAction<string>>;
  orgId: string;
}

const SelectBank = ({ orgId, setBankId, setBankRoutingNumber }: Props) => {
  const history = useHistory();
  const [defaultBanks, setDefaultBanks] = useState([]);
  const [shownBanks, setShownBanks] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);

  // Load priority (top 20) banks for initial/default display.
  React.useEffect(() => {
    // effect run once
    amplitude.setGroup("orgId", orgId);
    amplitude.track("Bank Link Initiated");

    setLoading(true);
    axios
      .get(`${getBackendUrl()}/banking/priority_banks`, {
        params: {
          org_id: orgId,
        },
      })
      .then((response) => {
        setDefaultBanks(response.data.banks);
        setShownBanks(defaultBanks);
      })
      .catch((e) => {
        captureException(e, { orgId });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setShownBanks(defaultBanks as any);
  }, [defaultBanks]);

  useEffect(() => {
    if (search === "") {
      setShownBanks(defaultBanks);
    }
  }, [defaultBanks, search]);

  const searchBanks = (e: any) => {
    const newSearch = e.target.value;
    setSearch(newSearch);
    if (newSearch === "") {
      setShownBanks(defaultBanks);
      return;
    }

    setLoading(true);
    axios
      .get(`${getBackendUrl()}/banking/search`, {
        params: {
          org_id: orgId,
          query: newSearch,
        },
      })
      .then((response) => {
        setShownBanks(response.data.banks);
      })
      .catch((e) => {
        captureException(e, { orgId });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const selectBank = (bank: any) => {
    setBankId(bank.platformId);
    setBankRoutingNumber(bank.routingNumber);
    if (bank.platform === "TELLER") {
      history.push("/teller");
    } else if (bank.platform === "PLAID") {
      history.push("/plaid");
    }
  };

  return (
    <>
      /
      <div className="p-4">
        <div className="pt-4 text-lg font-bold">Select your bank</div>
      </div>
      <input
        autoFocus
        className="m-2 mx-4 w-full font-lg outline-none"
        type="text"
        placeholder="Search"
        value={search}
        onChange={searchBanks}
      />
      {loading ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 99,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Spin></Spin>
        </div>
      ) : shownBanks.length > 0 ? (
        <div className="mt-2">
          {shownBanks.map((bank: any) => {
            return (
              <HoverBackground
                key={bank.platformId}
                onClick={() => {
                  amplitude.track("Bank Selected");
                  amplitude.setGroup("bank", bank.name);
                  selectBank(bank);
                }}
                className="py-4 flex items-center"
                style={{ borderBottom: "1px solid #F2F2F2" }}>
                <div>
                  {/* If logoUrl is also not defined, attempting to render as an image still leads to the bank name being properly spaced out. */}
                  {bank.logoBase64 ? (
                    <img
                      style={{ marginLeft: "1.6rem", height: "40px" }}
                      src={"data:image/jpeg;base64, " + bank.logoBase64}
                    />
                  ) : (
                    <img
                      style={{ marginLeft: "1.6rem", height: "40px" }}
                      src={bank.logoUrl}
                      alt=""
                    />
                  )}
                </div>
                <div className="ml-3">{bank.name}</div>
              </HoverBackground>
            );
          })}
        </div>
      ) : (
        <div
          style={{
            height: 300,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}>
          No results found.
        </div>
      )}
    </>
  );
};

export default SelectBank;

const HoverBackground = styled.div`
  cursor: pointer;
  overflow: hidden;
  &:hover {
    background-color: #f2f2f2;
  }
`;
