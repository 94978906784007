import { Button, Input } from "antd";
import axios from "axios";
import qs from "query-string";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { getBackendUrl } from "../../utils";
import IntuitBankFeedsCommonLayout from "./IntuitBankFeedsCommon";

const IntuitBankFeedsExtraFakeLogin: React.FC = () => {
  const history = useHistory();
  const { search } = history.location;
  const parsedSearch: any = qs.parse(search);
  const { challenge } = parsedSearch;

  const [clientId, setClientId] = useState<string>("");
  const [clientSecret, setClientSecret] = useState<string>("");
  const [accessToken, setAccessToken] = useState<string>("");

  const handleCompleteSubmit = async () => {
    const response = await axios.post(
      `${getBackendUrl()}/versioned/accounting/bank_feeds/otp?access_token=${accessToken}`,
      {},
      {
        headers: {
          Accept: "application/json",
          Authorization: `Basic ${btoa(`${clientId}:${clientSecret}`)}`,
          "X-Rutter-Version": "unstable",
        },
      },
    );

    const otp = response.data.bank_feed_otp.otp;
    const redirect_uri = response.data.bank_feed_otp.redirect_uri;
    const redirectUriWithPasswordAndChallenge = `${redirect_uri}?challenge=${challenge}&otp=${otp}`;
    window.location.replace(redirectUriWithPasswordAndChallenge);
  };

  return (
    <IntuitBankFeedsCommonLayout name={"OAUTH BANK LOGIN PAGE"}>
      <div className="mt-2">
        <Input
          placeholder="Client ID"
          value={clientId}
          title="Client ID"
          onChange={(e) => setClientId(e.target.value)}
        />
        <Input
          placeholder="Client Secret"
          type="password"
          value={clientSecret}
          title="Client Secret"
          onChange={(e) => setClientSecret(e.target.value)}
        />
        <Input
          placeholder="Access Token"
          value={accessToken}
          title="Access Token"
          onChange={(e) => setAccessToken(e.target.value)}
        />
      </div>
      <Button
        className="mt-3"
        type="primary"
        block
        disabled={!clientId || !clientSecret || !accessToken}
        onClick={handleCompleteSubmit}>
        Submit
      </Button>
    </IntuitBankFeedsCommonLayout>
  );
};

export default IntuitBankFeedsExtraFakeLogin;
