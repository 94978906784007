import { Alert } from "antd";
import { useState } from "react";
import { UNKNOWN_ERROR_MESSAGE } from "../constants";
import { captureException } from "../sentry";
import TellerLink from "./TellerLink";

type Props = {
  clientSecret: string;
  bankId?: string;
  onBack: () => void;
  onCancel: () => void;
  onComplete: (publicToken: string) => void;
};

function Teller({ bankId, onComplete }: Props) {
  const [error, setError] = useState<string>("");

  function handleAuthError(e: any) {
    captureException(e);
    if (e.response.data.error_message) {
      setError(e.response.data.error_message);
    } else {
      setError(UNKNOWN_ERROR_MESSAGE);
    }
  }

  function handleComplete(rutterPublicToken: string) {
    try {
      onComplete(rutterPublicToken);
    } catch (e) {
      handleAuthError(e);
    }
  }

  return (
    <>
      <div
        className="flex items-center flex-column mt-4"
        style={{ justifyContent: "center", paddingBottom: "20px" }}></div>
      <TellerLink bankId={bankId ?? ""} onComplete={handleComplete} />
      {error && (
        <Alert
          className="mt-4"
          type="error"
          message={"Error"}
          description={error}
          showIcon
        />
      )}
    </>
  );
}

export default Teller;
