import * as amplitude from "@amplitude/analytics-browser";
import { Identify } from "@amplitude/analytics-browser";
import { Alert } from "antd";
import axios from "axios";
import qs from "query-string";
import React from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import Link from "./Link";
import Spinner from "./components/Loading/Spinner";
import { captureException } from "./sentry";
import Store from "./stateStore";
import { AMAZON_REGION } from "./stores/Amazon";
import { getBackendUrl } from "./utils";

function LinkContainer() {
  const history = useHistory();
  const { search } = history.location;
  const parsedSearch = qs.parse(search);
  const {
    token,
    origin,
    nonce,
    platform,
    itemid,
    autoLoad,
    skipOverride,
    environment,
  } = parsedSearch; // token is the publicKey, prefixed with sandbox
  let store = Store.useStore();
  const [loading, setLoading] = React.useState(false);
  const [orgName, setOrgName] = React.useState("This app");
  const [orgId, setOrgId] = React.useState("");
  const [error, setError] = React.useState("");

  const isSandbox = (token as string)?.startsWith("sandbox");

  React.useEffect(() => {
    // effect run once
    store.set("publicKey")(token as string);
    store.set("nonce")(nonce as string);
    store.set("origin")(origin as string);
    store.set("isSandbox")(isSandbox);
    store.set("skipOverride")(skipOverride === "true");
    store.set("environment")(environment as string);

    amplitude.setGroup("orgId", orgId);
    amplitude.setGroup("orgPublicToken", token as string);
    amplitude.track("Main Link Initiated");
    if (typeof platform === "string") {
      amplitude.track("Platform Specific Main Link");
    }

    if (platform && !store.get("autoPlatform")) {
      store.set("autoPlatform")(platform as string);
    }
    if (itemid) {
      store.set("isUpdate")(true);
      store.set("itemId")(itemid as string);
    }
    if (autoLoad) {
      store.set("autoLoad")(autoLoad == "true" ? true : false);
    }
    setPlatformData(parsedSearch);

    setLoading(true);
    axios
      .get(`${getBackendUrl()}/link/init`, {
        params: {
          clientId: token,
        },
      })
      .then((response) => {
        const { data } = response;
        const { organization, platforms, authenticationConfigs, error } = data;

        if (error?.message === "Invalid clientId") {
          store.set("keyInvalid")(true);
        }
        const org = {
          ...organization,
          id: organization.id,
          name: organization.name,
          emergencyDeveloperEmail: organization.emergencyDeveloperEmail,
          privateShopifyScopes: organization.privateShopifyScopes,
          isPrivateShopify: organization.isPrivateShopify,
          bigCommerceScopes: organization.bigCommerceScopes,
          isPrivateBigCommerce: organization.isPrivateBigCommerce,
          linkLogoUrl: organization.linkLogoUrl,
          linkText: organization.linkText,
          platformPageText: organization.platformPageText,
          additionalPrivacyPolicyLink: organization.additionalPrivacyPolicyLink,
          prestashopScopes: organization.prestashopScopes,
          platformPageConnectUnderText:
            organization.platformPageConnectUnderText,
          whitelabelOptions: organization.whitelabelOptions,
        };
        setOrgName(organization.name);
        setOrgId(organization.id);
        store.set("platforms")(platforms);
        store.set("orgId")(organization.id as string);
        store.set("orgName")(organization.name as string);
        store.set("organization")(org);
        if (authenticationConfigs) {
          store.set("authenticationConfigs")(authenticationConfigs);
        }
        // do local storage...
        try {
          localStorage.setItem("rutterOrgId", organization.id);
        } catch (e) {
          captureException(e);
        }
        try {
          // set title of page
          document.title = `Connect with ${org.name}`;

          if (org.linkLogoUrl != null) {
            // set dynamic favicon
            var link = document.querySelector("link[rel~='icon']");
            if (!link) {
              link = document.createElement("link");
              (link as any).rel = "icon";
              document.getElementsByTagName("head")[0].appendChild(link);
            }
            (link as any).href = org.linkLogoUrl;
          }
        } catch {} // on errors

        // identify the session
        const identity = new Identify();
        identity.set("orgName", organization.name);
        amplitude.setUserId((itemid as string) ?? undefined);
        amplitude.identify(identity);
      })
      .catch((e) => {
        captureException(e, { itemid });
        if (e.message === "Network Error") {
          // user/dev
          setError("Error connecting to Rutter. Please try again later.");
        } else if (e?.response?.data?.error === "INVALID_PUBLIC_KEY") {
          // dev
          setError(
            "Invalid Rutter public key. To find your public key, visit https://dashboard.rutterapi.com",
          );
        } else {
          setError(
            "An unexpected error occurred, and we're looking into it. Please try again later.",
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const setPlatformData = (parsedSearch: qs.ParsedQuery) => {
    const { storeName, region, shopifystore } = parsedSearch; // https://docs.rutter.com/connections/connection-url
    const parsedStoreName = (storeName ?? shopifystore) as string | null;
    const parsedRegion =
      region && Object.values(AMAZON_REGION).includes(region as AMAZON_REGION)
        ? (region as AMAZON_REGION)
        : null;
    const platformData = {
      storeName: parsedStoreName,
      region: parsedRegion,
    };
    store.set("platformData")(platformData);
  };

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 99,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Alert
          type="error"
          message={"Error"}
          description={error}
          showIcon></Alert>
      </div>
    );
  }

  return (
    <>
      <Switch>
        <Route path="/">
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 99,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Link
              customerAction="connect to your store"
              nonce={nonce as string}
              origin={origin as string}
              customerName={orgName}
              organizationId={orgId as string}
              clientSecret={token as string}
              onSuccess={() => {}}
              onCancel={() => {
                // cancel the iframe
              }}
              onError={() => {}}
            />

            {isSandbox && (
              <div className="mt-4 bg-black p-2 text-white rounded">
                You are currently in Sandbox mode.
              </div>
            )}
          </div>
        </Route>
      </Switch>
    </>
  );
}

export default LinkContainer;
