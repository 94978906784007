import { useEffect, useState } from "react";
import useScript from "react-script-hook";
import {
  TellerConnectInstance,
  TellerConnectOptions,
} from "teller-connect-react";

// This code is copied from the teller-connect-react library. For some reason when using the original
// useTellerConnect method, the script gets stuck in a loading state and only on rare occasions proceeds
// to display the Teller login modal. As a (hopefully) temporary workaround, I've copied the code here
// below with the exception of the loading check, which is by default skip. Passing a skipOverride flag into Rutter
// Link will enable the original check (for testing in production).
// Original code can be found here: https://github.com/tellerhq/teller-connect-react/blob/master/src/useTellerConnect.ts
export const useTellerConnectOverride = (
  options: TellerConnectOptions,
  skipOverride: boolean,
) => {
  const [loading, error] = useScript({
    src: "https://cdn.teller.io/connect/connect.js",
    checkForExisting: true,
  });

  const [teller, setTeller] = useState<TellerConnectInstance | null>(null);
  const [iframeLoaded, setIframeLoaded] = useState(false);

  useEffect(() => {
    if (skipOverride && loading) {
      return;
    }

    if (!options.applicationId) {
      return;
    }

    if (error || !window.TellerConnect) {
      console.error("Error loading TellerConnect:", error);
      return;
    }

    if (teller != null) {
      teller.destroy();
    }

    const next = createTeller(
      {
        ...options,
        onInit: () => {
          setIframeLoaded(true);
          options.onInit && options.onInit();
        },
      },
      window.TellerConnect.setup,
    );

    setTeller(next);

    return () => next.destroy();
  }, [
    loading,
    error,
    options.applicationId,
    options.enrollmentId,
    options.connectToken,
    options.products,
  ]);

  const ready = teller != null && (!loading || iframeLoaded);

  const logIt = () => {
    if (!options.applicationId) {
      console.error(
        "teller-connect-react: open() called without a valid applicationId.",
      );
    }
  };

  return {
    error,
    ready,
    open: teller ? teller.open : logIt,
  };
};

interface ManagerState {
  teller: TellerConnectInstance | null;
  open: boolean;
}

const createTeller = (
  config: TellerConnectOptions,
  creator: (config: TellerConnectOptions) => TellerConnectInstance,
) => {
  const state: ManagerState = {
    teller: null,
    open: false,
  };

  if (typeof window === "undefined" || !window.TellerConnect) {
    throw new Error("TellerConnect is not loaded");
  }

  state.teller = creator({
    ...config,
    onExit: () => {
      state.open = false;
      config.onExit && config.onExit();
    },
  });

  const open = () => {
    if (!state.teller) {
      return;
    }

    state.open = true;
    state.teller.open();
  };

  const destroy = () => {
    if (!state.teller) {
      return;
    }

    state.teller.destroy();
    state.teller = null;
  };

  return {
    open,
    destroy,
  };
};
