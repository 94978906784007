import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  TellerConnectEnrollment,
  TellerConnectOnSuccess,
  TellerConnectOptions,
} from "teller-connect-react";
import { captureException } from "../sentry";
import Store from "../stateStore";
import { extractLinkParamsFromState, getBackendUrl } from "../utils";
import { useTellerConnectOverride } from "./useTellerConnectOverride";

// Relevant docs:
// https://github.com/tellerhq/teller-connect-react
// https://teller.io/settings/application
// https://teller.io/docs/guides/connect

const Link = ({
  bankId,
  onComplete,
}: {
  bankId?: string;
  onComplete: (rutterPublicToken: string) => void;
}) => {
  const history = useHistory();
  const stateStore = Store.useStore();
  const [tellerAppId, setTellerAppId] = useState("");
  const skipOverride = stateStore.get("skipOverride");
  const environment = getEnvironmentName(stateStore.get("environment"));

  const onSuccess = React.useCallback<TellerConnectOnSuccess>(
    (enrollment: TellerConnectEnrollment) => {
      const verifySignatureAndStoreAccessToken = async () => {
        try {
          const response = await axios.post(
            `${getBackendUrl()}/teller/set_access_token`,
            {
              platform_response: enrollment,
              environment,
              ...extractLinkParamsFromState(stateStore),
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            },
          );
          onComplete(response.data.public_token);
        } catch (error) {
          captureException(error);
        }
      };
      verifySignatureAndStoreAccessToken();
    },
    [],
  );

  const options: TellerConnectOptions = {
    applicationId: tellerAppId,
    environment,
    // If provided, will open Teller Connect directly to this institution.
    institution: bankId,
    // TODO(DEV-13958): Generate nonce on backend and pass in for signature verification.
    // nonce: "123246ds3asd4",
    onSuccess,
    onExit: () => {
      history.goBack();
    },
  };

  const { open, ready } = useTellerConnectOverride(options, skipOverride);

  useEffect(() => {
    if (ready && tellerAppId !== "") {
      open();
    }
  }, [ready, open, tellerAppId]);

  useEffect(() => {
    if (!tellerAppId) {
      try {
        axios
          .post(
            `${getBackendUrl()}/teller/get_app_id`,
            { ...extractLinkParamsFromState(stateStore) },
            {
              headers: {
                "Content-Type": "application/json",
              },
            },
          )
          .then((res) => {
            setTellerAppId(res.data.applicationId);
          });
      } catch (error) {
        captureException(error);
      }
    }
  }, [tellerAppId]);

  return <div></div>;
};

// If we receive an invalid environment, always default to production.
function getEnvironmentName(
  environment: string,
): "sandbox" | "development" | "production" {
  switch (environment) {
    case "development":
    case "production":
    case "sandbox":
      return environment;
    default:
      return "production";
  }
}

Link.displayName = "Link";

export default Link;
